import React from 'react';

function ServicesFAQ() {
  return (
    <div style={{ padding: '20px' }}>      

      <h3>Birth Doula Package</h3>
      <p><strong>Includes:</strong> two prenatal visits, birth plan consultation, email/phone/text support, birth support, and one postpartum visit. <strong>Cost:</strong> $850</p>

      <h3>Postpartum Support</h3>
      <p><strong>Cost:</strong> $25/hour</p>

      <h3>Frequently Asked Questions</h3>
      
      <p><strong>Q: Does a doula deliver babies?</strong><br/>
      A: Doulas are not medical professionals. They are trained to provide emotional support and encouragement. While doulas can provide resources to help mothers make informed decisions, they cannot give medical advice nor do they deliver babies.</p>

      <p><strong>Q: Do you only provide support for home births?</strong><br/>
      A: No! A doula can support a woman no matter where she chooses to birth: home, hospital, or birth center. Doulas can even be present for c-sections, both planned and unplanned.</p>

      <p><strong>Q: I have a midwife. Do I still need a doula?</strong><br/>
      A: A doula is not the same as a midwife. While the midwife's main objective during birth is to monitor the overall health of mom and baby, ensuring a safe delivery, a doula’s role is to provide emotional support for both the mother and her partner. Midwives are always grateful when a mother chooses to include a doula in her birth team.</p>

      <p><strong>Q: Do you assist with free births (planned unassisted births)?</strong><br/>
      A: While I am personally supportive of a woman’s decision to have a planned unassisted birth, I do not attend them at this time.</p>

      <p><strong>Q: What kind of support do you provide prior to birth?</strong><br/>
      A: Doulas provide educational resources, help women talk through their joys/fears about pregnancy, birth, and postpartum, give references for various professional services, and assist in thinking through a birth plan.</p>

      <p><strong>Q: My husband/sister/mother/friend/other plans to attend my birth. Why should I hire a doula?</strong><br/>
      A: A doula supplements and supports the support team, easing the burden from the support person or people. A doula is trained to understand physiological birth and how to support a laboring woman, which often eases the anxiety and fear a husband experiences during labor.</p>
    </div>
  );
}

export default ServicesFAQ;
